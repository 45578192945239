import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../../common/apiCallService';
import PayLoading from "./PayLoading";
import { connect, useSelector } from 'react-redux';
import * as actions from '../../../../Redux/actions/index';
import TruevoForm from "../../Deposit/TruevoForm";
import TruevoSecondForm from "../../Deposit/TruevoSecondForm";
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';
import { getDeviceIP } from '../../../../common/getIP';
import { constants } from '../../../../common/constants';
import PaymentSummaryForCreditCardBuy from './PaymentSummaryForCreditCardBuy';
import jwt from 'jsonwebtoken';
const CreditCardPayment = (props) => {
    const [creditCardNumber, setCreditCardNumber] = useState("");
    const api_url = useSelector((state) => state.config.api_url);
    const [routingId, setRoutingId] = useState("");
    const [acquirerName, setAcquirerName] = useState("");
    const [name, setName] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [CVVValue, setCVVValue] = useState("");
    const [payerAddress, setPayerAddress] = useState("");
    const [payerState, setPayerState] = useState("");
    const [payerCountry, setPayerCountry] = useState("");
    const [payerCity, setPayerCity] = useState("");
    const [payerZip, setPayerZip] = useState("");
    const [payerPhone, setPayerPhone] = useState("");
    const [saveCard, setSaveCard] = useState(false);
    const [error, setError] = useState("");
    const cardInput = useRef(null);
    const [token, setToken] = useState("");
    const [getTokenLoading, setGetTokenLoading] = useState(false)
    const [payFlag, setPayFlag] = useState(false);
    const [truevoFlag, setTruevoFlag] = useState(false);
    const [truevoSubmit, setTruevoSubmit] = useState(false);
    const [secondTruevoFlag, setSecondTruevoFlag] = useState(false);
    const [b2cColorMode, setB2CColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);
    const [countryCode, setcountryCode] = useState([]);
    const coinSpiritHostName = useSelector((state) => state.config.COIN_SPIRIT_HOST_NAME);
    const coinSpiritMailSupport = useSelector((state) => state.config.COIN_SPIRIT_MAIL_SUPPORT);
    const navigate = useNavigate();

    useEffect(() => {
        if (checkValueInSS("b2cColorMode")) {
            setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        }
        if (checkValueInSS("headerDarkTextColour")) {
            setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
        }
        if (checkValueInSS("headerLightTextColour")) {
            setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
        }
    }, []);

    useEffect(() => {
        if (cardInput.current) {
            cardInput.current.focus();
        }
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-country-code',
            {},
            (data) => {
                setcountryCode(data)
            })
    }, [])

    const checkCoinsSpirit = () => {
        return (window && window.location && window.location.hostname &&
            window.location.hostname.includes(coinSpiritHostName)) || false;
    };

    const getCardNumentMasked = (val) => {
        var newval = '';
        val = val.replace(/\s/g, '');
        for (var i = 0; i < val.length; i++) {
            if (i % 4 == 0 && i > 0) newval = newval.concat('  ');
            newval = newval.concat(val[i]);
        }
        return newval
    }

    const handleCommonSuccessApiResponse = (data) => {
        setPayFlag(false);
        if (data) {
            setGetTokenLoading(false)
            if (data.redirectUrl) {
                navigate(data.redirectUrl)
            } else if (data.acquirerRedirect) {
                window.location.href = data.acquirerRedirect
            } else {
                setToken(data);
                setPayFlag(true);
                props.handleLoadingModal();
            }
        }
    }
    const handleCommonSuccessApiResponseSimple = (data) => {
        if (data) {
            setToken(data);
            setPayFlag(true);
            props.handleLoadingModal();
        }
    }

    const commomErrorResponseHandler = (err) => {
        setError("Something Went Wrong!")
        console.error("errrr", err)
        setGetTokenLoading(false)
        setPayFlag(false);
        if (err.responseJSON && err.responseJSON.redirectUrl) {
            navigate(err.responseJSON.redirectUrl)
        } else {
            navigate("/creditCard/depositFailed?isB2c=true")
        }
    }

    const submitData = async () => {
        let ipAddress = await getDeviceIP()
        let date = expiryDate.substring(0, 2) + "/20" + expiryDate.substring(2, 4)
        let query = {
            name,
            expiryDate: date,
            CVVValue,
            creditCardNumber,
            fiatAmount: props.fiatAmount,
            fiatCurrency: props.fiatCurrency,
            saveCard: saveCard,
            payerAddress,
            payerState,
            payerCountry,
            payerCity,
            payerZip,
            payerPhone,
            ipAddress,
            transactionId: localStorage.getItem("transactionId"),
            "browser_timezone": new Date().toString().match(/([-\+][0-9]+)\s/)[1],
            "browser_color_depth": window.screen.colorDepth,
            "browser_language": navigator.language,
            "browser_screen_height": window.innerHeight,
            "browser_screen_width": window.innerWidth,
            "os": navigator.platform,
            "browserUserAgent": navigator.userAgent,
            domainNameUrl: localStorage.getItem("domainNameUrl"),
        }
        setGetTokenLoading(true)
        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/get-card-status', query, async (data) => {
            if (data) {
                if (data.code == "ACCEPT") {
                    query.fiatAmount = data.finalAmount ? data.finalAmount : props.fiatAmount;
                    props.setCardAPIType(data.integrationType)
                    setRoutingId(data.routingId)
                    setAcquirerName(data.acquirerName)
                    if (data.integrationType === "Trust Payments") {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/get-token', { ...query, routingId: data.routingId, acquirerName: data.acquirerName }, async (data) => {
                            if (data) {
                                handleCommonSuccessApiResponseSimple(data)
                            }
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === "Brava Payments") {
                        let filterdata = countryCode.filter((e) => e.name === query.payerCountry)
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/create-card-payment-brava',
                            {
                                ...query,
                                payerCountry: filterdata[0].code,
                                routingId: data.routingId,
                                acquirerName: data.acquirerName,
                                domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                            }, async (data) => {
                                if (data) {
                                    setGetTokenLoading(false)
                                    if (data.redirectUrl) {
                                        navigate(data.redirectUrl)
                                    } else if (data.acquirerRedirect) {
                                        window.location.href = data.acquirerRedirect
                                    } else {
                                        setToken(data);
                                        setPayFlag(true);
                                        props.handleLoadingModal();
                                    }
                                }
                            }, (err) => {
                                console.error("errrr", err)
                            })
                    } else if (data.integrationType === constants.GOLDEN_GATE_PAYMENTS_INTEGRATION_TYPE) {
                        setPayFlag(true);
                        let reqData = {
                            ...query,
                            "amount": data.finalAmount ? data.finalAmount : props.fiatAmount,
                            "fiatCurrency": props.fiatCurrency,
                            "description": "Payment",
                            "nameOnCard": name,
                            "name": name,
                            "creditCardNumber": creditCardNumber,
                            "expirationYear": "20" + expiryDate.substring(2, 4),
                            "expirationMonth": expiryDate.substring(0, 2),
                            "CVVValue": CVVValue,
                            "routingId": data.routingId,
                            "acquirerName": data.acquirerName,
                        }
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/golden-gateway-card-payment', reqData, async (data) => {
                            if (data) {
                                if (saveCard && ((data.redirectUrl && data.redirectUrl.includes("/creditCard/depositSuccessful")) || data.acquirerRedirectUrl)) {
                                    let tokenizeData = {
                                        ...query,
                                        "nameOnCard": name,
                                        "creditCardNumber": creditCardNumber,
                                        "expirationYear": "20" + expiryDate.substring(2, 4),
                                        "expirationMonth": expiryDate.substring(0, 2),
                                        "CVVValue": CVVValue,
                                        "paymentMethodBrand": data.paymentMethodBrand,
                                        "typeOfTokenization": "saveCard",
                                        "routingId": data.routingId,
                                        "acquirerName": data.acquirerName,
                                    }
                                    await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/golden-gateway-card-tokenize', tokenizeData, async (res) => {
                                        if (res) {
                                            console.log("card tokenized success")
                                        }
                                    }, (err) => {
                                        setError("Something Went Wrong!")
                                        console.error("errrr", err)
                                    })
                                }
                                if (data.acquirerRedirectUrl) {
                                    window.location.href = data.acquirerRedirectUrl
                                } else {
                                    navigate(data.redirectUrl)
                                    props.handleLoadingModal();
                                }
                            }
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === "AltPayNet") {
                        let reqData = {
                            ...query,
                            "routingId": data.routingId,
                            "acquirerName": data.acquirerName,
                        }
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/create-card-payment-alt-pay-net', reqData, async (data) => {
                            if (data) {
                                handleCommonSuccessApiResponse(data);
                            }
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === "Pay.com") {
                        setPayFlag(true);
                        let reqData = {
                            ...query,
                            "routingId": data.routingId,
                            "acquirerName": data.acquirerName,
                        }
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/create-card-payment-payDotCom', reqData, async (data) => {
                            handleCommonSuccessApiResponse(data);
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === "Axcess Payments") {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/get-axcess-payment-signature', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            if (data) {
                                setToken(data);
                                setPayFlag(true);
                                props.handleLoadingModal();
                            }
                        }, (err) => {
                            setError("Something Went Wrong!")
                            console.error("errrr", err)
                            setGetTokenLoading(false)
                            if (err?.responseJSON?.redirectUrl) {
                                navigate(err.responseJSON.redirectUrl)
                            } else {
                                navigate("/creditCard/depositFailed?isB2c=true")
                            }
                        })
                    } else if (data?.integrationType === constants.SWITCH_FIN_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/create-card-payment-switch-fin', {
                            ...query,
                            routingId: data?.routingId,
                            acquirerName: data?.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            if (data) {
                                const decoded = jwt.decode(data)
                                delete decoded?.iat
                                setToken(decoded?.switchFin);
                                setPayFlag(true);
                                props.handleLoadingModal();
                            }
                        }, (err) => {
                            setError("Something Went Wrong!")
                            console.error("errrr", err)
                            setGetTokenLoading(false)
                            if (err?.responseJSON?.redirectUrl) {
                                navigate(err.responseJSON.redirectUrl)
                            } else {
                                navigate("/creditCard/depositFailed?isB2c=true")
                            }
                        })
                    } else if (data?.integrationType === constants.WORLD_CARD_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/card-payment-world-card', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data?.integrationType === constants.BAERS_CREST_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/card-payment-baers-crest', {
                            ...query,
                            routingId: data?.routingId,
                            acquirerName: data?.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data?.integrationType === constants.PAYMENT_EMPIRE_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/payment-empire-card-payment', {
                            ...query,
                            routingId: data?.routingId,
                            acquirerName: data?.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data?.integrationType === constants.NEVO4_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/card-payment-nevo4', {
                            ...query,
                            routingId: data?.routingId,
                            acquirerName: data?.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === constants.FINTECH_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/fintech-card-payment', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            setPayFlag(false);
                            if (data) {
                                setGetTokenLoading(false)
                                if (data.redirectUrl) {
                                    navigate(data.redirectUrl)
                                } else if (data.acquirerRedirect) {
                                    const popupWindow = window.open(data.acquirerRedirect, '_blank')
                                    try {
                                        popupWindow.focus();
                                        navigate("/checkCardPayTransactionStatus?transactionId=" + query.transactionId)
                                    } catch (e) {
                                        alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
                                    }
                                } else {
                                    setToken(data);
                                    setPayFlag(true);
                                    props.handleLoadingModal();
                                }

                            }
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data?.integrationType === constants.TRUEVO_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/truevo-card-payment', {
                            ...query,
                            routingId: data?.routingId,
                            acquirerName: data?.acquirerName,
                            browser_timezone: new Date().getTimezoneOffset(),
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            getTruevoStatus();
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    }
                } else {
                    setError("This card is blocked")
                    setGetTokenLoading(false)
                    navigate("/creditCard/depositFailed?isB2c=true");
                }
            }
        }, (err) => {
            console.error("errrr", err)
        })
    }

    const getTruevoStatus = async () => {
        try {
            let query = {
                transactionId: localStorage.getItem("transactionId")
            }
            await apiService("/restapi/getTruevoStatus", query, (data) => {
                if (data?.redirectUrl) {
                    navigate(data.redirectUrl)
                } else {
                    if (data?.outcome?.transactionStatus !== "PENDING") {
                        return
                    } else if ((data?.outcome?.action?.actionRequired === "IdentifyCardholder") && !truevoSubmit) {
                        if (data?.outcome?.action?.additionalData?.length > 0) {
                            setToken(data.outcome.action.additionalData);
                        }
                        setTruevoFlag(true);
                        return;
                    } else if (data?.outcome?.action?.actionRequired === "Challenge") {
                        if (data?.outcome?.action?.additionalData?.length > 0) {
                            setToken(data.outcome.action.additionalData);
                        }
                        setSecondTruevoFlag(true);
                        return;
                    } else {
                        getTruevoStatus();
                    }
                }
            }, (err) => {
                console.error(err);
            })
        } catch (error) {
            console.error('error getTruevoStatus ', error);
        }
    }
    const getExpiryDateMasked = (val) => {
        var newval = '';
        val = val.replace(/\s/g, '');
        for (var i = 0; i < val.length; i++) {
            if (i % 2 == 0 && i > 0 && i < 3) newval = newval.concat(' / ');
            newval = newval.concat(val[i]);
        }
        return newval
    }

    let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

    let disabledCondition = CVVValue && CVVValue.length === 3 && !isNaN(CVVValue) &&
        creditCardNumber && creditCardNumber.length === 16 && !isNaN(creditCardNumber) &&
        expiryDate && expiryDate.length === 4 && !isNaN(expiryDate) && name && name.length > 2
        && payerAddress && payerAddress !== ""
        && payerState && payerState !== ""
        && payerCity && payerCity !== ""
        && payerCountry && payerCountry.length >= 2
        && payerZip && payerZip !== ""
        && payerPhone && payerPhone !== ""
        && !getTokenLoading ? true : false

    let requiredComp = <span style={{ color: "red" }}>*</span>

    return (
        <div className={`creditCardContainer ${props.lightFlag ? "text_color_black" : "text_color_white"}`} id={checkCoinsSpirit() ? "creditCardPaymentSummaryWidth" : ""}>
            <div className='creditCardPaymentSummary header__profile_block'>
            <PaymentSummaryForCreditCardBuy {...props} />
            <div className={checkCoinsSpirit() ? "creditCardPaymentSummaryDetailsPaymentMethodSelection" : ""}>
            {!payFlag && <div className={`creditCardPaymentFiled`}>
                <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                    <div className="title">Card Number {requiredComp}</div>
                    <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""} ${props.lightFlag ? "text_color_black" : "text_color_white"}`}>
                        <input type="text"
                            value={getCardNumentMasked(creditCardNumber)}
                            onChange={(e) => {
                                setCreditCardNumber((e.target.value.replace(/\s/g, '')).replace(/\D[^\.]/g, "").substring(0, 16))
                                setError("")
                            }}
                            ref={cardInput}
                            pattern="[0-9]+"
                            data-e2e="card-number"
                            className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                            placeholder="••••  ••••  ••••  ••••" />
                    </div>
                </div>
                <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                    <div className="title">Name on Card {requiredComp}</div>
                    <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                        <input type="text"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                                setError("")
                            }}
                            data-e2e="card-name"
                            className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                            placeholder="Enter card holders name" />
                    </div>
                </div>
                <div className="creditCardInputContainer">
                    <div className={`creditCardInputWrap creditCardInputExpiryWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                        <div className="title">Expiry date {requiredComp}</div>
                        <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                            <input type="text"
                                value={getExpiryDateMasked(expiryDate)}
                                onChange={(e) => {
                                    setExpiryDate((e.target.value.replace(/\s/g, '')).replace(/[^0-9.]/g, "").substring(0, 4))
                                    setError("")
                                }}
                                data-e2e="expiried-data"
                                className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                placeholder="MM / YY" />
                        </div>
                    </div>
                    <div className={`creditCardInputWrap creditCardInputCVVWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                        <div className="title">CVV/CVC {requiredComp}</div>
                        <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                            <input type="text"
                                value={CVVValue}
                                onChange={(e) => {
                                    setCVVValue((e.target.value.replace(/\s/g, '')).replace(/[^0-9.]/g, "").substring(0, 3))
                                    setError("")
                                }}
                                data-e2e="card-cvv"
                                className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                placeholder="123" />
                        </div>
                    </div>
                </div>
                <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                    <div className="title">Address {requiredComp}</div>
                    <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                        <input type="text"
                            value={payerAddress}
                            onChange={(e) => {
                                setPayerAddress(e.target.value)
                                setError("")
                            }}
                            data-e2e="address"
                            className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                            placeholder="Enter address" />
                    </div>
                </div>
                <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                    <div className="title">State {requiredComp}</div>
                    <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                        <input type="text"
                            value={payerState}
                            onChange={(e) => {
                                setPayerState(e.target.value)
                                setError("")
                            }}
                            data-e2e="state"
                            className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                            placeholder="Enter State" />
                    </div>
                </div>
                <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                    <div className="title">Country Code {requiredComp}</div>
                    <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                        <select
                            value={payerCountry}
                            className={`${payerCountry !== "" ? props.lightFlag ? "text_color_black " : "text_color_white " : "select-default-option"} ${props.lightFlag ? "inputField" : "selectField"}`}
                            onChange={(e) => {
                                setPayerCountry(e.target.value)
                                setError("")
                            }}
                        >
                            {payerCountry === "" &&
                                <option style={{ height: "50px" }} value={""} disabled className='font-weight-light' data-e2e="country-drop-down">Select 2-digit contry code (e.g. US)</option>
                            }
                            {countryCode && countryCode.length > 0 && countryCode.map((data, index) => {
                                return (
                                    <option key={index} style={{ height: "50px" }} className={`${props.lightFlag ? "text_color_black " : "text_color_white "}`} data-e2e={data.name} value={data.name}>{data.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                    <div className="title">City {requiredComp}</div>
                    <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                        <input type="text"
                            value={payerCity}
                            onChange={(e) => {
                                setPayerCity(e.target.value)
                                setError("")
                            }}
                            data-e2e="city-name"
                            className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                            placeholder="Enter city name" />
                    </div>
                </div>
                <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                    <div className="title">Zip Code {requiredComp}</div>
                    <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                        <input type="text"
                            value={payerZip}
                            onChange={(e) => {
                                setPayerZip(e.target.value)
                                setError("")
                            }}
                            data-e2e="zip-code"
                            className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                            placeholder="Enter zip code" />
                    </div>
                </div>
                <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                    <div className="title">Phone {requiredComp}</div>
                    <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                        <input type="text"
                            value={payerPhone}
                            onChange={(e) => {
                                setPayerPhone(e.target.value)
                                setError("")
                            }}
                            data-e2e="phone-number"
                            className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                            placeholder="Enter phone number" />
                    </div>
                </div>
                <div className="saveAndErrorWrap">
                    <div className="saveThisCard">
                        <div className={`tickBox ${props.lightFlag ? "tickBoxLight" : ""}`} onClick={() => {
                            setSaveCard(!saveCard)
                        }}>
                            {saveCard && <i data-e2e="save-card-checkbox" className="fa fa-check-square" />}
                        </div>
                        <div className="title">Save this card</div>
                    </div>
                    {error !== "" && <div className="errorMsg">{error}</div>}
                </div>
            </div>}
            {!payFlag && <div className={`ANC_Continue_btn ${disabledCondition ? "" : "ANC_Continue_btn_disabled"}`}
                style={disabledCondition ? { ...headerButtonStyle } : {}}
                onClick={() => {
                    if (disabledCondition) {
                        submitData();
                    } else if (!(creditCardNumber && creditCardNumber.length === 16 && !isNaN(creditCardNumber))) {
                        setError("Invalid Card Number")
                    } else if (!(expiryDate && expiryDate.length === 4 && !isNaN(expiryDate))) {
                        setError("Invalid Expiry Date")
                    } else if (!(CVVValue && CVVValue.length === 3 && !isNaN(CVVValue))) {
                        setError("Invalid CVC / CVV")
                    } else {
                        setError("Invalid Details!")
                    }
                }} data-e2e="deposit">Deposit</div>}
                <div className='creditCardFooterImages mt-10'>
                    <p>{`Contact support on ${coinSpiritMailSupport}`}</p>
                    <p className="footer__copyright__images">
                        <img loading="lazy" decoding="async" src={`${props.image_base_url}/upd/icons/mastercard-securecode.png`} alt="" width="96" height="54" />
                        <img loading="lazy" decoding="async" src={`${props.image_base_url}/upd/icons/mastercard-securecode1.png`} alt="" width="96" height="54" />
                    </p>
                </div>
            {payFlag && <PayLoading
                customerName={name}
                cardNumber={creditCardNumber}
                cardExpiryMonth={expiryDate.substring(0, 2)}
                cardExpiryYear={expiryDate.substring(2, 4)}
                cardCVV={CVVValue}
                routingId={routingId}
                acquirerName={acquirerName}
                transactionId={localStorage.getItem("transactionId")}
                saveCard={saveCard} token={token}
                axcess_hosted_url={props.axcess_hosted_url}
            />}

                    {truevoFlag && <TruevoForm
                        truevoContent={token}
                        handleTruevoFlag={() => {
                            setTruevoSubmit(true);
                            setTruevoFlag(false);
                            setTimeout(() => {
                                getTruevoStatus()
                            }, 2000);
                            return
                        }
                        }
                    />}
                    {secondTruevoFlag && <TruevoSecondForm
                        truevoContent={token}
                        handleTruevoFlag={() => {
                            setTimeout(() => {
                                getTruevoStatus()
                            }, 500);
                            return
                        }
                        }
                    />}

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cardAPIType: state.deposit.cardAPIType,
        axcess_hosted_url: state.config.axcess_hosted_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCardAPIType: (value) => dispatch(actions.setCardAPIType(value)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CreditCardPayment);